.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-overlay-backdrop {
  background: rgba(#003665, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  animation: fadeIn 0.25s linear;
}

.cdk-overlay-pane {
  pointer-events: auto;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
}
