@keyframes slideIn {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes alert {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(-100%) scaleY(1);
    animation-timing-function: ease-in;
  }
  40% {
    transform: translateY(0) scaleY(1);
    animation-timing-function: ease-out;
  }
  44% {
    transform: translateY(0) scaleY(0.8);
    animation-timing-function: ease-out;
  }
  48% {
    transform: translateY(0) scaleY(1);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateY(-100%) scaleY(1);
    animation-timing-function: ease-in;
  }
}
