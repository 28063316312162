:root {
  --btn-background: #00aeef;
  --btn-hover-background: #0089bc;
  --btn-color: #ffffff;
  --color-success: #5ba215;
  --color-success-background: #f6faf2;
  --color-background-light: #eef6fa;
  --heading: #00aeef;
  --reading: #003665;
}

$screen-lg: 1024px;
