.btn {
  background: var(--btn-background);
  color: var(--btn-color);
  border-radius: 20px;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  border: none;
  display: inline-block;
  font-size: 1.33em;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    background: var(--btn-hover-background);
  }
}

.btn--block {
  max-width: 100%;
  width: 280px;
}
