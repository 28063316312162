@font-face {
  font-family: 'icomoon';
  src: url('/assets/fonts/icomoon.eot?1o68fz');
  src: url('/assets/fonts/icomoon.eot?1o68fz#iefix') format('embedded-opentype'),
    url('/assets/fonts/icomoon.ttf?1o68fz') format('truetype'),
    url('/assets/fonts/icomoon.woff?1o68fz') format('woff'),
    url('/assets/fonts/icomoon.svg?1o68fz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: '\e900';
}
.icon-goal_scored:before {
  content: '\e902';
}
.icon-play:before {
  content: '\e901';
}
