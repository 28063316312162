@font-face {
  font-family: 'MuseoSans';
  src: url('/assets/fonts/museosans-300-webfont.eot');
  src: url('/assets/fonts/museosans-300-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/museosans-300-webfont.woff') format('woff'),
    url('/assets/fonts/museosans-300-webfont.ttf') format('truetype'),
    url('/assets/fonts/museosans-300-webfont.svg#museosanslight') format('svg');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'MuseoSans';
  src: url('/assets/fonts/museosans-500-webfont.eot');
  src: url('/assets/fonts/museosans-500-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/museosans-500-webfont.woff') format('woff'),
    url('/assets/fonts/museosans-500-webfont.ttf') format('truetype'),
    url('/assets/fonts/museosans-500-webfont.svg#museosansregular') format('svg');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'MuseoSans';
  src: url('/assets/fonts/museosans-700-webfont.eot');
  src: url('/assets/fonts/museosans-700-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/museosans-700-webfont.woff') format('woff'),
    url('/assets/fonts/museosans-700-webfont.ttf') format('truetype'),
    url('/assets/fonts/museosans-700-webfont.svg#museosansbold') format('svg');
  font-weight: 700;
  font-style: normal;
}
