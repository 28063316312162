@use 'styles/variables';
@use 'styles/fonts';
@use 'styles/icons';
@use 'styles/animations';
@use 'styles/overlay';
@use 'styles/buttons';

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-size: 12px;
  color: var(--reading);
  font-family: 'MuseoSans';
}

.container {
  max-width: 672px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
}

.container--flex {
  display: flex;
}

.image-wrapper {
  border-radius: 8px;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
  }
}

p {
  font-weight: 300;
  font-size: 1.15em;
  line-height: 150%;
}
